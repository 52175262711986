import SideNav, { Toggle, Nav, NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav';

// Be sure to include styles at some point, probably during your bootstraping
import '@trendmicro/react-sidenav/dist/react-sidenav.css';
import { useNavigate } from 'react-router-dom';
import HomeOutlinedIcon from '@mui/icons-material/Home';
import DataObjectIcon from '@mui/icons-material/DataObject';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import ModelTrainingIcon from '@mui/icons-material/ModelTraining';
import OnlinePredictionIcon from '@mui/icons-material/OnlinePrediction';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import AccessibilityIcon from '@mui/icons-material/Accessibility';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import GitHubIcon from '@mui/icons-material/GitHub';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import WorkIcon from '@mui/icons-material/Work';
import G2PDeep_Logo from '../images/G2PDeep_logo.png'
import G2PDeep_Logo_Collapsed from '../images/G2PDeep_logo_collapsed.png'
import { logoutFakeUser } from './Auth/logoutFakeUser';
import { Link } from 'react-router-dom';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import { event } from 'jquery';

export default function SideNavigationBar({ token, setToken }) {

    let navigate = useNavigate();

    if (token == null) {
        token = sessionStorage.getItem('token');
    }

    const logOut = (event) => {
        // event.preventDefault()
        logoutFakeUser(setToken, navigate);
    }

    return (
        <div >
            <SideNav
                className="navbar"
                defaultExpanded
                onSelect={(selected) => {
                    if (selected === 'logout') {
                        logOut(event);
                    } else {
                        navigate(selected);
                    }
                }}
            >
                <Link to='/'><img className='logo' src={G2PDeep_Logo} /></Link>
                {/* <img className='logo_collapsed' src={G2PDeep_Logo_Collapsed} /> */}
                {/* <SideNav.Toggle /> */}
                <SideNav.Nav defaultSelected="home" className="expanded-nav">
                    {/* <div> */}
                    <NavItem eventKey="workshop">
                        <NavIcon>
                            <WorkIcon />
                        </NavIcon>
                        <NavText>
                            Workshop
                        </NavText>
                    </NavItem>
                    <NavItem eventKey="introduction">
                        <NavIcon>
                            <HomeOutlinedIcon />
                        </NavIcon>
                        <NavText>
                            Introduction
                        </NavText>
                    </NavItem>
                    <NavItem eventKey="datasets">
                        <NavIcon>
                            <DataObjectIcon />
                        </NavIcon>
                        <NavText>
                            Datasets
                        </NavText>
                    </NavItem>
                    <NavItem eventKey="models">
                        <NavIcon>
                            <ModelTrainingIcon />
                        </NavIcon>
                        <NavText>
                            Models
                        </NavText>
                    </NavItem>
                    <NavItem eventKey="projects">
                        <NavIcon>
                            <AccountTreeIcon />
                        </NavIcon>
                        <NavText>
                            Projects
                        </NavText>
                    </NavItem>
                    <NavItem eventKey="predict">
                        <NavIcon>
                            <OnlinePredictionIcon />
                        </NavIcon>
                        <NavText>
                            Prediction
                        </NavText>
                    </NavItem>
                    <NavItem eventKey="study_results">
                        <NavIcon>
                            <SchoolOutlinedIcon />
                        </NavIcon>
                        <NavText>
                            Study Results
                        </NavText>
                    </NavItem>
                    <NavItem eventKey="publish">
                        <NavIcon>
                            <LibraryBooksIcon />
                        </NavIcon>
                        <NavText>
                            Literature Evidence
                        </NavText>
                    </NavItem>
                    <NavItem eventKey="user_guide">
                        <NavIcon>
                            <AccessibilityIcon />
                        </NavIcon>
                        <NavText>
                            User Guide
                        </NavText>
                    </NavItem>
                    <NavItem eventKey="github">
                        <NavIcon>
                            <GitHubIcon />
                        </NavIcon>
                        <NavText>
                            GitHub &nbsp;
                            <OpenInNewIcon sx={{ fontSize: 15 }}  />
                        </NavText>
                    </NavItem>
                    {/* </div>
                    <div> */}
                    {token == null ? (
                        <NavItem eventKey="login">
                            <NavIcon>
                                <LoginIcon />
                            </NavIcon>
                            <NavText>
                                Login/Signup
                            </NavText>
                        </NavItem>)
                        :
                        (<NavItem eventKey="logout">
                            <NavIcon>
                                <LogoutIcon />
                            </NavIcon>
                            <NavText>
                                Logout
                            </NavText>
                        </NavItem>
                        )}
                    {/* </div> */}
                </SideNav.Nav>

                <SideNav.Nav defaultSelected="home" className="collapsed-nav">
                <NavItem eventKey="workshop"  className="navigation-item">
                        <NavIcon>
                            <WorkIcon />
                        </NavIcon>
                    </NavItem>
                    <NavItem eventKey="introduction"  className="navigation-item">
                        <NavIcon>
                            <HomeOutlinedIcon />
                        </NavIcon>
                    </NavItem>
                    <NavItem eventKey="datasets"  className="navigation-item">
                        <NavIcon>
                            <DataObjectIcon />
                        </NavIcon>
                    </NavItem>
                    <NavItem eventKey="models"  className="navigation-item">
                        <NavIcon>
                            <ModelTrainingIcon />
                        </NavIcon>
                    </NavItem>
                    <NavItem eventKey="projects"  className="navigation-item">
                        <NavIcon>
                            <AccountTreeIcon />
                        </NavIcon>
                    </NavItem>
                    <NavItem eventKey="predict"  className="navigation-item">
                        <NavIcon>
                            <OnlinePredictionIcon />
                        </NavIcon>
                    </NavItem>
                    <NavItem eventKey="study_results"  className="navigation-item">
                        <NavIcon>
                            <SchoolOutlinedIcon />
                        </NavIcon>
                    </NavItem>
                    <NavItem eventKey="publish"  className="navigation-item">
                        <NavIcon>
                            <LibraryBooksIcon />
                        </NavIcon>
                    </NavItem>
                    <NavItem eventKey="user_guide"  className="navigation-item">
                        <NavIcon>
                            <AccessibilityIcon />
                        </NavIcon>
                    </NavItem>
                    <NavItem eventKey="github"  className="navigation-item">
                        <NavIcon>
                            <GitHubIcon />
                        </NavIcon>
                    </NavItem>
                    {token == null ? (
                        <NavItem eventKey="login"  className="navigation-item">
                            <NavIcon>
                                <LoginIcon />
                            </NavIcon>
                        </NavItem>)
                        :
                        (<NavItem eventKey="logout"  className="navigation-item">
                            <NavIcon>
                                <LogoutIcon />
                            </NavIcon>
                        </NavItem>
                        )}

                </SideNav.Nav>
            </SideNav>
        </div>
    );
}
